import Pxp from './pxp';
import Nazar from './123nazar';
const Logo = () => {
	if (process.env.PREACT_APP_SITE === 'pxp') {
		return <Pxp />;
	}
	return <Nazar />;
};

export default Logo;
