/* eslint-disable compat/compat */
import './style';
import App from './components/app';

export default App;

if ('serviceWorker' in navigator) {
	navigator.serviceWorker.getRegistrations().then(registrations => {
		for (let registration of registrations) {
			registration.unregister();
		}
	});
}
