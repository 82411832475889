import { h } from "preact";
import style from "./style";
import Logo from "../logo";
const Header = ({ logo }) => (
  <header class={style.header}>
    {logo ? (
      <img src={logo} style={{ height: "50px" }} />
    ) : (
      <Logo className={style.logo} />
    )}
  </header>
);

export default Header;
