/* eslint-disable react/jsx-indent-props */
import { h, Component } from "preact";
import { Router } from "preact-router";

import Header from "./header";
import style from "./style.css";
// Code-splitting is automated for routes
import Home from "../routes/home";
import Vote from "../routes/vote";

import { Dictionary } from "../dictionary";

const LinkToSite = () => {
  if (process.env.PREACT_APP_SITE === "pxp") {
    return <a href="https://pressnxpress.com">Press'nXPress</a>;
  }
  return <a href="https://feedback.center">Feedback Center</a>;
};

const LinkToPolicy = () => {
  if (process.env.PREACT_APP_SITE === "pxp") {
    return <a href="https://pressnxpress.com/privacy-policy">Privacy Policy</a>;
  }
  return <a href="https://feedback.center"> Privacy Policy</a>;
};

export default class App extends Component {
  state = {
    logo: undefined,
  };

  /** Gets fired when the route changes.
   *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
   *	@param {string} event.url	The newly routed URL
   */
  handleRoute = (e) => {
    this.currentUrl = e.url;
  };

  setLogo = (logo) => {
    this.setState({ logo });
  };

  componentDidMount() {
    document.title = Dictionary.get("title");
  }

  render({}, { logo }) {
    return (
      <div id="app">
        <Header logo={logo} />
        <Router onChange={this.handleRoute}>
          <Home path="/" />
          <Vote setLogo={this.setLogo} path="/vote/:token" />
        </Router>
        <p class={style.term}>
          Powered by
          <LinkToSite /> <LinkToPolicy />
        </p>
      </div>
    );
  }
}
